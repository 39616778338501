<template>
  <section class="m-user-info m-scroll">

    <!-- 头部导航 -->
    <m-header title="个人信息" >
      <span slot="right" @click="onSave">保存</span>
    </m-header>

    <div class="avatar van-hairline--top">
      <van-uploader :after-read="onRead">
        <div class="upload-slot">
          <img :src="user.pic_url == '' ? $store.state.avatar :  user.pic_url" alt="avatar">
          <p>点击修改头像</p>
        </div>
      </van-uploader>
    </div>

    <div class="info">
      <div class="info-cell">
        <div class="label van-hairline--bottom">用户账号</div>
        <div class="right van-hairline--bottom account">{{user.phone}}</div>
      </div>
      <div class="info-cell">
        <div class="label van-hairline--bottom">昵称</div>
        <div class="right van-hairline--bottom">
          <input type="text" v-model="user.nickname">
        </div>
      </div>
      <div class="info-cell">
        <div class="label">性别</div>
        <div class="right">
          <van-radio-group v-model="user.sex" direction="horizontal">
            <van-radio name="1" checked-color="#ED2A24">男</van-radio>
            <van-radio name="2" checked-color="#ED2A24">女</van-radio>
            <van-radio name="0" checked-color="#ED2A24">保密</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

  





    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import {  getAccountInfo, editAccountInfo } from '@/api/zh/mine.js'
import { upload } from '@/api/public.js'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      user: {
        phone: '',
        nickname: '',
        sex: '0',
        pic_url: '',
        pic_id: '',
      },
    }
  },

  

  methods:{

    // 获取用户信息
    getData() {
      getAccountInfo().then(res => {
        if(res) {
          this.user = res.data
          this.user.sex = this.user.sex + ''
        }
      })
    },



    // 点击保存
    onSave(){
      let params = {
        sex: this.user.sex,
        pic: this.user.pic_id
      }
      if(this.user.nickname == "") {
        this.$notify({ type: 'warning', message: '昵称不能为空' })
        return false
      }else {
        params.nickname = this.user.nickname
      }

      editAccountInfo(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.$router.go(-1)
        } 
      })
    },

    // 上传插件 读取完成本地文件时
    onRead(file){

      var image = new FormData()
      image.append('filename', 'Avatar')
      image.append(`file`, file.file)
      upload(image).then(res => {
        if(res) {
          this.user.pic_url = res.data.url
          this.user.pic_id  = res.data.pic_id
        }
      })
      
    }


  },

  created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import './user.less';
</style>